<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
    <!-- <div> -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title style="height: 60px">
        <h4>COD Report</h4>
        <!-- <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">ลูกค้า</span> -->
        <v-spacer />
      </v-card-title>
    </v-card>
    <!-- search -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon color="blue accent-2">fa-sliders-h</v-icon>
        <span class="ml-2">ค้นหา</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row row>
          <v-col cols="12" md="2" lg="1" class="pb-3">
            <span>วันที่ : </span>
          </v-col>
          <v-col cols="12" md="2" class="pb-3">
            <v-menu
              ref="search_menu_date_from"
              v-model="search_menu_date_from"
              :close-on-content-click="false"
              :return-value.sync="search_date_from"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="computed_search_date_from"
                  @change="(value) => (computed_search_date_from = value)"
                  autocomplete="off"
                  label="เริ่ม"
                  outlined
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  hide-details
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search_date_from"
                @input="
                  (search_menu_date_from = false),
                    $refs.search_menu_date_from.save(search_date_from)
                "
                no-title
                :allowed-dates="allowedDates"
                class="my-0"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="2" class="pb-3">
            <v-menu
              ref="search_menu_date_to"
              v-model="search_menu_date_to"
              :close-on-content-click="false"
              :return-value.sync="search_date_to"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="computed_search_date_to"
                  @change="(value) => (computed_search_date_to = value)"
                  autocomplete="off"
                  label="สิ้นสุด"
                  outlined
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  hide-details
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search_date_to"
                @input="
                  (search_menu_date_to = false),
                    $refs.search_menu_date_to.save(search_date_to)
                "
                no-title
                :allowed-dates="allowedDates"
                class="my-0"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="2" lg="1" class="pb-3">
            <span>สร้างโดย : </span>
          </v-col>
          <v-col cols="12" md="4" lg="4" class="pb-3">
            <v-text-field
              v-model="search.createdBy"
              @change="(value) => (search.createdBy = value)"
              outlined
              dense
              hide-details
              autocomplete="off"
            />
          </v-col>
        </v-row>
        <v-row row>
          <v-col class="pb-3" cols="12" md="2" lg="1">
            <h4>ขนส่ง :</h4>
          </v-col>
          <v-col class="pb-3" cols="12" md="4" lg="4">
            <v-select
              ref="refexpress"
              v-model="search.express"
              autocomplete="off"
              :items="express"
              item-text="name"
              item-value="id"
              label="เลือกขนส่ง"
              dense
              hide-details
              outlined
            />
          </v-col>
          <v-col cols="12" md="2" lg="1" class="pb-3">
            <span>ตรวจโดย : </span>
          </v-col>
          <v-col cols="12" md="4" lg="4" class="pb-3">
            <v-text-field
              v-model="search.approvedBy"
              @change="(value) => (search.approvedBy = value)"
              outlined
              dense
              hide-details
              autocomplete="off"
            />
          </v-col>
        </v-row>
        <v-row row>
          <v-col cols="12" md="2" lg="1" class="pb-3">
            <span>ยอดเงิน : </span>
          </v-col>
          <v-col cols="12" md="4" lg="4" class="pb-3">
            <v-text-field
              v-model="search.amount"
              @change="(value) => (search.amount = value)"
              outlined
              dense
              hide-details
              autocomplete="off"
            />
          </v-col>
          <v-col cols="12" md="4" lg="4" class="pb-3">
            <v-btn dark color="primary" @click="load_data()" class="mr-3">ค้นหา</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- datatable -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title>
        <!-- <v-icon color="primary" >mdi-file-chart-outline </v-icon> -->
        <v-icon color="yellow darken-2">fa-file-invoice-dollar </v-icon>
        <span class="ml-2">COD</span>
        <v-spacer />
        <v-btn
          :elevation="1"
          color="primary"
          dark
          @click="DialogUpdate(null)"
          class="fn-14 te"
        >
          <v-icon left>mdi-plus</v-icon>
          เพิ่ม
        </v-btn>
        <!-- <v-btn color="success" dark @click="exportexcel()" class="fn-14" outlined>
                    Export To Excel
                </v-btn> -->
        <!-- <json-excel
                    :fetch = "fetchData"
                    :fields = "json_fields"
                    worksheet="สรุปยอด COD"
                    :name="namefile"
                >
                    <v-btn color="success" dark @click="exportexcel()" class="fn-14" outlined>
                        Export To Excel
                    </v-btn>
                </json-excel> -->
      </v-card-title>
      <v-divider />
      <v-card-text class="hidden-xs-only">
        <!-- <div class="pb-2 pr-2 text-right">
                    <v-btn :elevation="1" color="primary" dark @click="DialogUpdate(null)" class="fn-14 te">
                        <v-icon left>mdi-plus</v-icon>
                        เพิ่ม
                    </v-btn>
                </div> -->
        <v-data-table
          :headers="headersCOD"
          :items="dataTableCOD"
          class="elevation-0 packhai-border-table"
          hide-default-footer
          id="datatablexs"
          :items-per-page="50"
          :mobile-breakpoint="0"
        >
          <template v-slot:item.No="{ item }">
            {{ dataTableCOD.indexOf(item) + 1 }}
          </template>
          <template v-slot:item.Express="{ item }">
            {{ item.expressCompanyName }}
          </template>
          <template v-slot:item.BankDate="{ item }">
            {{ formatDate(item.bankDate) }}
          </template>
          <template v-slot:item.Bank="{ item }">
            {{ item.bankName }}
          </template>
          <template v-slot:item.Amount="{ item }">
            {{ item.amount == null ? "0.00" : format_price(item.amount) }}
          </template>
          <template v-slot:item.Created="{ item }">
            {{ formatDate(item.created) }}
          </template>
          <template v-slot:item.CreatedByStaffBranch="{ item }">
            {{ item.createdByStaffBranch }}
          </template>
          <template v-slot:item.ApprovedByStaffBranch="{ item }">
            {{ item.approvedByStaffBranch }}
          </template>
          <template v-slot:item.DateApprove="{ item }">
            {{ formatDate(item.approved) }}
          </template>
          <template v-slot:item.Detail="{ item }">
            <a @click="loaddetailtrack(item)">รายละเอียด</a>
          </template>
          <template v-slot:item.Status="{ item }">
            <span v-if="item.approvedByName == null" class="orange--text darken-4"
              >รอคืนยอด</span
            >
            <span v-else class="success--text">คืนเรียบร้อย</span>
          </template>
          <template v-slot:item.Approve="{ item }">
            <v-btn
              :loading="loading_btn"
              :disabled="loading_btn"
              outlined
              v-if="item.approvedByName == null"
              color="purple"
              @click="Approve(item)"
            >
              <span>คืนยอด</span>
            </v-btn>
            <v-btn icon v-else disabled>
              <span></span>
            </v-btn>
          </template>
          <template v-slot:item.Edit="{ item }">
            <v-btn icon v-if="item.approvedByName == null" @click="DialogUpdate(item)">
              <v-icon size="18">fa-edit</v-icon>
            </v-btn>
            <!-- <v-btn icon v-else disabled>
                            <v-icon size="18">fa-edit</v-icon>
                        </v-btn> -->
          </template>
          <template v-slot:item.Delete="{ item }">
            <v-btn v-if="item.approvedByName == null" icon @click="DeleteCodReport(item)">
              <v-icon size="18" color="red darken-1">fa-trash-alt</v-icon>
            </v-btn>
            <!-- <v-btn icon v-else disabled>
                            <v-icon size="18" >fa-trash-alt</v-icon>
                        </v-btn> -->
          </template>
        </v-data-table>
      </v-card-text>
      <!-- hidden-sm-and-up -->
      <v-card-text class="hidden-sm-and-up">
        <v-simple-table class="packhai-border-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left"></th>
                <th class="text-center" style="min-width: 150px">วันที่สร้าง</th>
                <th class="text-center" style="min-width: 100px">สร้างโดย</th>
                <th class="text-center" style="min-width: 100px">ยอด</th>
                <th class="text-center" style="min-width: 100px">รายละเอียด</th>
                <th class="text-center" style="min-width: 100px">แก้ไข</th>
                <th class="text-center" style="min-width: 100px">ลบ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in dataTableCOD" :key="index">
                <td class="text-center">
                  {{ dataTableCOD.indexOf(item) + 1 }}
                </td>
                <td class="text-center">{{ formatDate(item.created) }}</td>
                <td class="text-center">{{ item.createdByName }}</td>
                <td class="text-center">
                  {{ item.amount == null ? "0.00" : format_price(item.amount) }}
                </td>
                <td class="text-center">
                  <a @click="loaddetailtrack(item)">รายละเอียด</a>
                </td>
                <td class="text-center">
                  <v-btn
                    icon
                    v-if="item.approvedByName == null"
                    @click="DialogUpdate(item)"
                  >
                    <v-icon size="18">fa-edit</v-icon>
                  </v-btn>
                  <v-btn icon v-else disabled>
                    <v-icon size="18">fa-edit</v-icon>
                  </v-btn>
                </td>
                <td class="text-center">
                  <v-btn
                    v-if="item.approvedByName == null"
                    icon
                    @click="DeleteCodReport(item)"
                  >
                    <v-icon size="18" color="red darken-1">fa-trash-alt</v-icon>
                  </v-btn>
                  <v-btn icon v-else disabled>
                    <v-icon size="18">fa-trash-alt</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-row class="mx-0 mt-5">
      <v-col cols="12" align="center">
        <div class="text-center" @click="changePage()">
          <v-pagination
            v-model="page"
            :length="pageLength"
            :total-visible="7"
            color="primary"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>

    <v-dialog
      v-model="DialogUpdateCodReport"
      scrollable
      :fullscreen="isFullscreen"
      max-width="60%"
      persistent
    >
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <span v-if="addcod.id == 0">เพิ่ม COD</span>
          <span v-else>แก้ไข COD</span>
          <v-spacer />
          <v-btn icon @click="DialogUpdateCodReport = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text class="overflow-y-auto">
          <v-form ref="DialogAddInvoiceForm" lazy-validation>
            <v-row row>
              <v-col cols="12" md="3" lg="2" class="pb-2">
                <h4>วันที่เงินเข้า :</h4>
              </v-col>
              <v-col cols="12" md="7" lg="8" class="pb-2">
                <!-- <v-menu
                            ref="menu"
                            v-model="menu_edit_date"
                            :close-on-content-click="false"
                            :return-value.sync="model"
                            transition="scale-transition"
                            offset-y
                            max-width="560px"
                            min-width="560px"
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="addcod.date"
                                    autocomplete="off"
                                    outlined
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    hide-details
                                    dense
                                    v-on="on"
                                ></v-text-field>
                            </template>

                            <div class="v-date-time-widget-container">
                                <v-layout row wrap>
                                    <v-flex xs12 sm6>
                                        <v-date-picker
                                            v-model="dateModel"
                                            width="240"
                                            mode="dateTime" is24hr
                                            :no-title="true"
                                            color="primary"></v-date-picker>

                                    </v-flex>
                                    <v-flex xs12 sm6>  -->
                <!-- <v-btn
                                            fab
                                            :color="getMeridiamButtonColor('AM')"
                                            @click="meridiam='AM'">AM</v-btn>

                                        <v-btn
                                                fab
                                                :color="getMeridiamButtonColor('PM')"
                                                class="btn-pm"
                                                @click="meridiam='PM'">PM</v-btn> -->

                <!-- <v-time-picker
                                            v-if="menu_edit_date"
                                            v-model="timeModel"
                                            color="primary"
                                            width="240"
                                            :no-title="true"
                                            use-seconds
                                            ></v-time-picker>

                                        <h3 class="text-xs-center">{{ currentSelection }}</h3>
                                    </v-flex>

                                    <v-flex xs12 sm6 class="text-left pl-3">
                                        <v-btn @click="menu_edit_date = false">Cancel</v-btn>
                                    </v-flex>
                                    <v-flex xs12 sm6 class="text-right pr-3">
                                        <v-btn @click="confirm()">Ok</v-btn>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </v-menu>  -->
                <v-flex>
                  <v-datetime-picker
                    v-model="addcod.date"
                    time-format="HH:mm:ss"
                    :time-picker-props="timeProps"
                    :text-field-props="textFieldProps"
                  >
                    <template slot="dateIcon">
                      <v-icon>fas fa-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>fas fa-clock</v-icon>
                    </template>
                    <template slot="actions" slot-scope="{ parent }">
                      <!-- <v-btn color="error lighten-1" @click.native="parent.clearHandler">Cancel</v-btn> -->
                      <v-btn color="success darken-1" @click="parent.okHandler"
                        >Done</v-btn
                      >
                    </template>
                  </v-datetime-picker>
                </v-flex>
              </v-col>
            </v-row>
            <v-row row>
              <v-col cols="12" md="3" lg="2" class="pb-2">
                <h4>ยอดเงิน :</h4>
              </v-col>
              <v-col cols="12" md="7" lg="8" class="pb-2">
                <v-text-field
                  :value="addcod.amount"
                  @change="(value) => (addcod.amount = value)"
                  ref="refAddAmount"
                  @keypress="isNumberWithDot($event)"
                  outlined
                  dense
                  hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row row>
              <v-col cols="12" md="3" lg="2" class="pb-2">
                <h4>ขนส่ง :</h4>
              </v-col>
              <v-col cols="12" md="7" lg="8" class="pb-2">
                <v-select
                  ref="refexpress"
                  v-model="addcod.express"
                  autocomplete="off"
                  :items="express"
                  item-text="name"
                  item-value="id"
                  label="เลือกขนส่ง"
                  dense
                  hide-details
                  outlined
                />
              </v-col>
            </v-row>
            <v-row row>
              <v-col cols="12" md="3" lg="2" class="pb-2">
                <h4>ธนาคาร :</h4>
              </v-col>
              <v-col cols="12" md="7" lg="8" class="pb-2">
                <v-select
                  ref="refbank"
                  v-model="addcod.bank"
                  autocomplete="off"
                  :items="bank"
                  item-text="accountName"
                  item-value="id"
                  label="เลือกธนาคาร"
                  dense
                  hide-details
                  outlined
                >
                  <template slot="selection" slot-scope="data">
                    <v-col cols="11" class="mx-0 pl-0">
                      <v-avatar size="25px" v-if="data.item.logo != null">
                        <img :src="data.item.logo" />
                      </v-avatar>
                      {{
                        (data.item.accountNumber == null ? "" : data.item.accountNumber) +
                        " " +
                        data.item.accountName
                      }}
                    </v-col>
                  </template>
                  <template slot="item" slot-scope="data">
                    <v-col class="ml-0">
                      <v-avatar size="25px" v-if="data.item.logo != null">
                        <img :src="data.item.logo" />
                      </v-avatar>
                      {{
                        (data.item.accountNumber == null ? "" : data.item.accountNumber) +
                        " " +
                        data.item.accountName
                      }}
                    </v-col>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row row>
              <v-col cols="12" md="3" lg="2" class="pb-2">
                <h4>หมายเหตุ :</h4>
              </v-col>
              <v-col cols="12" md="7" lg="8" class="pb-2">
                <v-textarea
                  :value="addcod.remark"
                  @change="(value) => (addcod.remark = value)"
                  ref="refAddRemark"
                  outlined
                  dense
                  hide-details
                  autocomplete="off"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row row>
              <v-col cols="12" md="3" lg="2" class="pb-2">
                <h4>เพิ่มเลขแทรค :</h4>
              </v-col>
              <v-col cols="12" md="7" lg="8" class="pb-2">
                <v-textarea
                  :value="addcod.track"
                  @change="(value) => (addcod.track = value)"
                  ref="refAddTrack"
                  outlined
                  dense
                  hide-details
                  autocomplete="off"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="2" lg="2" class="pb-2 align-self-end">
                <v-btn dark color="teal" class="mr-1" @click="addtrack()">
                  <v-icon>mdi-plus</v-icon>
                  <span>เพิ่มแทรค</span>
                  <span v-if="loadingtrack != 0">({{ loadingtrack }}%)</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-row row>
            <v-col cols="12" md="3" lg="2" class="pb-2">
              <!-- <v-btn v-show="dataTableTrack.length != 0" size="1" color="success" dark class="mr-1">
                    <vue-excel-xlsx
                        :data="dataTableTrack"
                        :columns="excel_track_header"
                        :filename="'CodReport'"
                        :sheetname="'CodReport'"
                        >
                        Export to Excel
                    </vue-excel-xlsx>
                </v-btn> -->
              <vue-excel-xlsx
                :data="dataTableTrack"
                :columns="excel_track_header"
                :file-name="'track'"
                :file-type="'xlsx'"
                :sheet-name="'track'"
              >
                <v-btn color="success" outlined v-if="dataTableTrack.length != 0"
                  >Export to Excel</v-btn
                >
              </vue-excel-xlsx>
            </v-col>
          </v-row>
          <v-row row>
            <v-col cols="12" class="pb-2">
              <v-data-table
                :headers="headersTrack"
                :items="dataTableTrack"
                class="elevation-0 packhai-border-table"
                hide-default-footer
                :items-per-page="1000"
                :mobile-breakpoint="0"
              >
                <template v-slot:item.Delete="{ item }">
                  <v-btn icon @click="DeleteTrack(item)">
                    <v-icon size="18">fa-trash-alt</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.No="{ item }">
                  {{ dataTableTrack.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.OrderID="{ item }">
                  {{ FormatOrder(item.orderMasterId) }}
                </template>
                <template v-slot:item.Shop="{ item }">
                  {{ item.shopName }}
                </template>
                <template v-slot:item.TrackingNo="{ item }">
                  {{ item.trackingNo }}
                </template>
                <template v-slot:item.Amount="{ item }">
                  {{ format_price(item.codamount) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <span class="text-left">
            จำนวนแทรค {{ dataTableTrack.length }} <br />
            ยอดเงิน
            {{ codamount == null ? format_price(0) : format_price(codamount) }}
            บาท
          </span>
          <v-spacer />
          <v-btn
            dark
            :elevation="1"
            color="primary"
            class="mr-1 text-right"
            @click="updatecodconfirm()"
          >
            <v-icon left>mdi-content-save</v-icon>
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="DialogDialogDetailReport"
      scrollable
      :fullscreen="isFullscreen"
      max-width="60%"
      persistent
    >
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <span>ข้อมูล COD</span>
          <v-spacer />
          <v-btn icon @click="DialogDialogDetailReport = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text class="overflow-y-auto">
          <v-row row>
            <v-col cols="12" md="3" lg="2" class="pb-2"> ขนส่ง : </v-col>
            <v-col cols="12" md="6" lg="6" class="pb-2">
              {{ addcod.express }}
            </v-col>
          </v-row>
          <v-row row>
            <v-col cols="12" md="3" lg="2" class="pb-2"> ธนาคาร : </v-col>
            <v-col cols="12" md="6" lg="6" class="pb-2">
              {{ addcod.bank == null ? " - " : addcod.bank }}
            </v-col>
          </v-row>
          <v-row row>
            <v-col cols="12" md="3" lg="2" class="pb-2"> วันที่เงินเข้า : </v-col>
            <v-col cols="12" md="6" lg="6" class="pb-2">
              {{ formatDate(addcod.bankDate) }}
            </v-col>
          </v-row>
          <v-row row>
            <v-col cols="12" md="3" lg="2" class="pb-2"> วันที่อนุมัติ : </v-col>
            <v-col cols="12" md="6" lg="6" class="pb-2">
              {{ formatDate(addcod.approved) }}
            </v-col>
          </v-row>
          <v-row row>
            <v-col cols="12" md="3" lg="2" class="pb-2"> คนอนุมัติ : </v-col>
            <v-col cols="12" md="6" lg="6" class="pb-2">
              {{ addcod.approvedByName == null ? " - " : addcod.approvedByName }}
            </v-col>
          </v-row>
          <v-row row>
            <v-col cols="12" md="3" lg="2" class="pb-2"> สถานะ : </v-col>
            <v-col cols="12" md="6" lg="6" class="pb-2">
              <span
                v-if="this.addcod.approvedByName == null"
                class="orange--text darken-4"
                >รอคืนยอด</span
              >
              <span v-else class="success--text">คืนเรียบร้อย</span>
            </v-col>
          </v-row>

          <div class="pt-2 pr-4 text-right">
            <!-- <json-excel
                            :data = "dataTableDetailcod"
                            :fields = "json_fields"
                            worksheet="สรุปยอด COD"
                            header="รายงาน COD"
                            :name="namefile"
                            :meta   = "json_meta"
                        >
                            <v-btn color="success" dark @click="exportexcel()" class="fn-14" outlined>
                                Export To Excel
                            </v-btn>
                        </json-excel> -->
            <!-- <button type="button" @click="exportToExcel">Excel download</button> -->
            <!-- <v-btn color="success" dark @click="exportToExcel()" class="fn-14" outlined>
                            Export To Excel
                        </v-btn> -->
            <vue-excel-xlsx
              :data="dataTableDetailcod"
              :columns="json_fields"
              :file-name="filename"
              :file-type="'xlsx'"
              :sheet-name="'sheetname'"
            >
              <v-btn color="success" dark @click="exportexcel()" class="fn-14" outlined>
                Export To Excel
              </v-btn>
            </vue-excel-xlsx>
          </div>
          <v-row row>
            <v-col cols="12" class="pb-2">
              <div class="py-3">
                <table
                  width="100%"
                  border="0"
                  style="border-collapse: collapse"
                  id="sheetjs"
                >
                  <tr class="border-a">
                    <th width="10%" align="center" class="py-2 border-right">ลำดับ</th>
                    <th width="20%" align="center" class="py-2 border-right">
                      เลขที่ออเดอร์
                    </th>
                    <th width="25%" align="center" class="py-2 border-right">ร้านค้า</th>
                    <th width="25%" align="center" class="py-2 border-right">
                      TrackingNo
                    </th>
                    <th width="20%" align="center" class="py-2 border-right">ยอดเงิน</th>
                  </tr>
                  <tr
                    Valign="top"
                    v-for="(i, index) in dataTableDetailcod"
                    v-bind:key="index"
                  >
                    <td align="center" class="px-2 py-1 border-x">
                      {{ index + 1 }}
                    </td>
                    <td align="center" class="px-2 py-1 border-right">
                      {{ FormatOrder(i.orderMasterId) }}
                    </td>
                    <td align="center" class="px-2 py-1 border-right">
                      {{ i.shopName }}
                    </td>
                    <td align="center" class="px-2 py-1 border-right">
                      {{ i.trackingNo }}
                    </td>
                    <td align="right" class="px-2 py-1 border-right">
                      {{
                        i.codamount == null ? format_price(0) : format_price(i.codamount)
                      }}
                    </td>
                  </tr>
                  <tr height="50" Valign="top" class="border-bottom">
                    <td align="left" class="px-2 border-x"></td>
                    <td align="left" class="px-2 border-right"></td>
                    <td align="right" class="px-2 border-right"></td>
                    <td align="right" class="px-2 border-right"></td>
                    <td align="right" class="px-2 border-right"></td>
                  </tr>
                  <tr Valign="center" class="border-bottom">
                    <td align="left" colspan="3" class="px-2 py-1 border-a">
                      <span class="font-weight-bold">หมายเหตุ / remark</span><br />
                      <span v-if="addcod.remark != null">{{ addcod.remark }}</span>
                      <span v-else> </span>
                    </td>
                    <td align="left" colspan="1" class="px-2 py-0 border-a">
                      <v-row>
                        <v-col cols="12" align="right" class="font-weight-bold py-0 pt-1"
                          >ยอดรวม</v-col
                        >
                      </v-row>
                    </td>
                    <td align="left" class="px-2 py-0 border-a">
                      <v-row>
                        <v-col cols="12" align="right" class="py-0 pt-1">
                          {{ format_price(addcod.amount) }}
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                </table>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Loading from "@/website/components/Loading";
import axios from "axios";
import Swal from "sweetalert2";
import { branchService_dotnet, generalService_dotnet } from "@/website/global";
import {
  isNumberWNoDot,
  isNumberWithDot,
  format_price,
  FormatOrder,
} from "@/website/global_function";
import { token_api, header_token } from "@/website/token";
import moment from "moment";

export default {
  components: {
    Loading,
  },
  data: () => ({
    page_loading: true,
    loading: false,
    loading_btn: false,
    isFullscreen: false,
    permission: null,
    postcode_mapping: null,
    search_menu_date_from: false,
    search_menu_date_to: false,
    menu_edit_date: false,
    textFieldProps: { appendIcon: "mdi-calendar" },
    dateProps: {
      headerColor: "red",
    },
    timeProps: {
      useSeconds: true,
      ampmInTitle: true,
    },
    datetime: "",
    bankdate: null,
    express: [],
    bank: [],

    // search
    search_date_from: null,
    search_date_to: null,
    search: {
      createdBy: null,
      amount: null,
      approvedBy: null,
      express: 0,
    },
    headersCOD: [
      {
        text: "ลำดับ",
        align: "center",
        sortable: false,
        value: "No",
        width: "10px",
      },
      { text: "ขนส่ง", align: "center", sortable: false, value: "Express" },
      {
        text: "วันที่เงินเข้าธนาคาร",
        align: "center",
        sortable: false,
        value: "BankDate",
      },
      { text: "ธนาคาร", align: "center", sortable: false, value: "Bank" },
      { text: "ยอด", align: "center", sortable: false, value: "Amount" },
      {
        text: "วันที่สร้าง",
        align: "center",
        sortable: false,
        value: "Created",
      },
      {
        text: "สร้างโดย",
        align: "center",
        sortable: false,
        value: "CreatedByStaffBranch",
      },
      {
        text: "คืนยอดโดย",
        align: "center",
        sortable: false,
        value: "ApprovedByStaffBranch",
      },
      {
        text: "เวลาคืนยอด",
        align: "center",
        sortable: false,
        value: "DateApprove",
      },
      { text: "รายละเอียด", align: "center", sortable: false, value: "Detail" },
      { text: "สถานะ", align: "center", sortable: false, value: "Status" },
      { text: "คืนยอด", align: "center", sortable: false, value: "Approve" },
      { text: "แก้ไข", align: "center", sortable: false, value: "Edit" },
      { text: "ลบ", align: "center", sortable: false, value: "Delete" },
    ],
    dataTableCOD: [],

    headersTrack: [
      { text: "ลบ", align: "center", sortable: false, value: "Delete" },
      {
        text: "ลำดับ",
        align: "left",
        sortable: false,
        value: "No",
        width: "10px",
      },
      { text: "OrderID", align: "left", sortable: false, value: "OrderID" },
      { text: "ร้านค้า", align: "left", sortable: false, value: "Shop" },
      {
        text: "TrackingNo",
        align: "left",
        sortable: false,
        value: "TrackingNo",
      },
      { text: "ยอด COD", align: "left", sortable: false, value: "Amount" },
    ],
    dataTableTrack: [],

    headersDetail: [
      {
        text: "ลำดับ",
        align: "center",
        sortable: false,
        value: "No",
        width: "10px",
      },
      {
        text: "เลขที่ออเดอร์",
        align: "center",
        sortable: false,
        value: "OrderID",
      },
      { text: "ร้านค้า", align: "center", sortable: false, value: "Shop" },
      {
        text: "TrackingNo",
        align: "center",
        sortable: false,
        value: "TrackingNo",
      },
      { text: "ยอด COD", align: "right", sortable: false, value: "Amount" },
    ],
    dataTableDetailcod: [],
    addcod: {
      id: 0,
      amount: parseFloat(0),
      remark: null,
      track: null,
      // "date": new Date(),
      date: new Date(),
      express: null,
      bank: 0,
      ordermasterid: null,
      approvedByName: null,
      approved: null,
      bankDate: null,
    },
    codamount: null,
    // DateTime
    timeModel: null,
    dropdownOpen: false,
    meridiam: "AM",
    displayDate: "",
    dateModel: "",

    // paginations
    isPage: 0,
    page: 1,
    page_tmp: null,
    limits: 50,
    offsets: 0,
    pageLength: null,

    // exporttoexcel
    footer: {},
    filename: null,
    json_meta: [[{ key: "charset", value: "utf-8" }]],
    json_fields: [
      {
        label: "เลขที่ออเดอร์",
        field: "orderMasterId",
        dataFormat: FormatOrder,
      },
      { label: "ร้านค้า", field: "shopName", width: 10 },
      { label: "TrackingNo", field: "trackingNo", width: 30 },
      { label: "ยอด COD", field: "codamount", dataFormat: format_price },
    ],
    excel_track_header: [
      { label: "OrderID", field: "orderMasterId", width: 20 },
      { label: "ร้านค้า", field: "shopName", width: 20 },
      { label: "Trackingno", field: "trackingNo", width: 30 },
      { label: "ยอด Cod", field: "codAmount", width: 15 },
    ],
    DialogUpdateCodReport: false,
    DialogDialogDetailReport: false,
    datatrackdelete: [],
    deleteindextrack: -1,

    loadingtrack: 0,
  }),
  mounted() {
    // Set the current date and time as default value
    var d = new Date();
    var currentHour = d.getHours() % 24; // AM,PM format
    var minutes = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
    var second = d.getSeconds();
    var currentTime = currentHour + ":" + minutes + ":" + second;
    this.timeModel = currentTime;
    this.dateModel = d.toISOString().substr(0, 10);

    if (d.getHours() >= 12) {
      this.meridiam = "PM";
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(model) {},
    },

    currentSelection() {
      let selectedTime = this.timeModel;
      return this.formatDates(this.dateModel) + " " + selectedTime;
    },
    computed_search_date_from() {
      return this.formatDates(this.search_date_from);
    },
    computed_search_date_to() {
      return this.formatDates(this.search_date_to);
    },
    //   computed_edit_date() {
    //     return this.formatDateTime(this.addcod.date)
    //   },
  },
  async created() {
    this.permission = JSON.parse(localStorage.getItem("permission"));
    await this.load_data();
    this.page_loading = false;
  },
  watch: {},
  methods: {
    format_price,
    isNumberWithDot,
    isNumberWNoDot,
    FormatOrder,
    allowedDates: (val) => val <= new Date().toISOString().substr(0, 10),
    formatDates(date) {
      if (date == null) {
        return null;
      } else {
        const [year, month, day] = date.split("-");
        return `${year}-${month}-${day}`;
      }
    },
    formatDateTime(date) {
      if (date == null) {
        return null;
      } else {
        return moment(String(date)).format("YYYY-MM-DD HH:mm:ss");
      }
    },
    formatDate(date) {
      if (date == null) {
        return null;
      } else {
        return moment(String(date)).format("DD-MM-YYYY HH:mm:ss");
      }
    },
    // Confirm the datetime selection and close the popover
    confirm() {
      this.onUpdateDate();
      this.menu_edit_date = false;
    },

    // Format the date and trigger the input event
    onUpdateDate() {
      if (!this.dateModel || !this.timeModel) return false;

      // let selectedTime = this.timeModel + ' ' + this.meridiam;
      let selectedTime = this.timeModel;
      this.addcod.date = this.formatDates(this.dateModel) + " " + selectedTime;
      this.$emit("input", this.dateModel + " " + selectedTime);
    },

    // Set the active state for the meridiam buttons
    getMeridiamButtonColor(m) {
      if (m === this.meridiam) {
        return "primary";
      } else {
        return "darkgray";
      }
    },
    async changePage() {
      this.loading = true;
      this.offsets = this.page * this.limits - this.limits;
      if (this.page > 1 && this.page < this.pageLength) {
        if (this.page_tmp != this.page) {
          await this.load_data();
          this.page_tmp = this.page;
        }
      } else if ((this.page == 1, this.pageLength)) {
        if (this.page_tmp != this.page) {
          await this.load_data();
          this.page_tmp = this.page;
        }
      }
      this.loading = false;
    },
    async load_data() {
      this.loading = true;
      await axios
        .all([
          axios.post(
            branchService_dotnet + "CodReport/get-codreport-info-list",
            {
              DateFrom: this.search_date_from,
              DateTo: this.search_date_to,
              CreatedByName: this.search.createdBy,
              Amount: this.search.amount,
              ApprovedByName: this.search.approvedBy,
              ExpressCompanyId: this.search.express,
              branchId: localStorage.getItem("Branch_BranchID"),
              isAscending: false,
              skip: this.offsets,
              take: this.limits,
            },
            { headers: header_token }
          ),
          axios.post(
            branchService_dotnet + "CodReport/get-express-info-list",
            {},
            { headers: header_token }
          ),
        ])
        .then(
          axios.spread((Report, express) => {
            if (Report.status == 200) {
              this.dataTableCOD = Report.data.codReportList;
              this.search_date_from = Report.data.dateFrom;
              this.search_date_to = Report.data.dateTo;
              this.pageLength = Math.ceil(Report.data.searchResultCount / this.limits);
              if (this.pageLength <= 0) {
                this.pageLength = 1;
              }
              this.pageTemp = this.page;
            }
            if (express.status == 200) {
              this.express = express.data;
            }
          })
        );
      this.loading = false;
    },
    async get_bank() {
      await axios
        .all([
          axios.post(
            branchService_dotnet + "CodReport/get-bankcod",
            { branchId: localStorage.getItem("Branch_BranchID") },
            { headers: header_token }
          ),
        ])
        .then(
          axios.spread((bank) => {
            if (bank.status == 200) {
              this.bank = bank.data;
              var ba = {
                accountName: "--กรุณาเลือกธนาคาร--",
                id: 0,
                logo: null,
              };
              this.bank.splice(0, 0, ba);
            }
          })
        );
    },
    async DialogUpdate(cod) {
      this.loading = true;
      if (cod == null) {
        await this.clearaddcod();
        await this.get_bank();
        // this.addcod.date = this.formatDateTime(this.addcod.date)
        this.DialogUpdateCodReport = true;
      } else {
        await this.clearaddcod();
        await this.get_bank();
        await axios
          .all([
            axios.post(
              branchService_dotnet + "CodReport/get-codreport-info",
              {
                CodReportId: cod.id,
              },
              { headers: header_token }
            ),
          ])
          .then(
            axios.spread((Report) => {
              if (Report.status == 200) {
                this.addcod = {
                  id: Report.data.id,
                  amount: Report.data.amount,
                  remark: Report.data.remark,
                  date: this.formatDateTime(Report.data.created),
                  track: null,
                  express: Report.data.expressCompanyId,
                  bank: Report.data.bankId,
                  approvedByName: Report.data.approvedByName,
                  approved: Report.data.approved,
                  bankDate: Report.data.bankDate,
                };
                this.dataTableTrack = Report.data.orderList;
                for (var i = 0; i < this.dataTableTrack.length; i++) {
                  this.codamount = this.codamount + this.dataTableTrack[i]["codAmount"];
                }
                this.DialogUpdateCodReport = true;
              }
            })
          );
      }
      this.loading = false;
    },
    async addtrack() {
      this.loading = true;
      if (this.datatrackdelete.length != 0) {
        var tracklist = this.addcod.track.split(/[.,!,?,\n,\t]/);
        // var tracklist = ["TH73011NTCPA9M","TH73061NT4DA7I"]
        for (var i = 0; i < tracklist.length; i++) {
          var deletelisthave = false;
          // var indexdeleted = null
          for (var del = 0; del < this.datatrackdelete.length; del++) {
            if (tracklist[i] == this.datatrackdelete[del]["trackingNo"]) {
              deletelisthave = true;
              // indexdeleted = this.datatrackdelete[del]
              break;
            }
          }
          if (deletelisthave == true) {
            this.dataTableTrack.push({
              codAmount: this.datatrackdelete[del]["codAmount"],
              orderMasterId: this.datatrackdelete[del]["orderMasterId"],
              shopName: this.datatrackdelete[del]["shopName"],
              trackingNo: this.datatrackdelete[del]["trackingNo"],
            });
            this.codamount = this.codamount + this.datatrackdelete[del]["codAmount"];
            this.datatrackdelete.splice(this.datatrackdelete[del], 1);
          }
        }
      }
      if (this.addcod.track != null) {
        let Temptracklist = this.addcod.track.split(/[.,!,?,\n,\t]/);
        let tracklist = [];
        for (let index = 0; index < Temptracklist.length; index++) {
          if (Temptracklist[index] != "") {
            tracklist.push(Temptracklist[index].trim());
          }
        }
        var distinctTrack = [...new Set(tracklist)];
        tracklist = [];
        for (let index = 0; index < distinctTrack.length; index++) {
          var datahave = false;
          for (let dt = 0; dt < this.dataTableTrack.length; dt++) {
            if (this.dataTableTrack[dt]["trackingNo"] == distinctTrack[index]) {
              datahave = true;
              break;
            }
          }
          if (!datahave) {
            tracklist.push(distinctTrack[index]);
          }
        }
        let Tempdatatable = [];
        let limits = 10;
        let offsets = 0;
        this.loadingtrack = 1;
        let total = tracklist.length;
        let round = parseInt(total / limits);
        if (limits * round < total) {
          round = round + 1;
        }
        for (let i = 1; i <= round; i++) {
          let data_tracklist = tracklist.slice(0, limits);
          let track = await this.GetTracking(data_tracklist, limits, offsets);
          if (track.length != 0 && track != false) {
            for (let t = 0; t < track.orderListInfos.length; t++) {
              Tempdatatable.push(track.orderListInfos[t]);

              // Tempdatatable.push({
              //   "codAmount": track.orderListInfos[t]["codAmount"],
              //   "orderMasterId": track.orderListInfos[t]["orderMasterId"],
              //   "shopName": track.orderListInfos[t]["shopName"],
              //   "trackingNo": track.orderListInfos[t]["trackingNo"],
              // });
              this.codamount = this.codamount + track.orderListInfos[t]["codamount"];
            }
            if (this.addcod.express == null) {
              this.addcod.express = track.expressCompanyId;
            }
          } else if (track.length == 0) {
            Tempdatatable = [];
            break;
          }
          this.loadingtrack = parseInt(
            (parseFloat(i) / parseFloat(round)) * parseFloat(100)
          );
          offsets = offsets + limits;
          tracklist = tracklist.splice(limits);
        }
        this.addcod.amount = this.codamount;
        if (Tempdatatable.length != 0) {
          this.dataTableTrack = this.dataTableTrack.concat(Tempdatatable);
        }
        this.loadingtrack = this.dataTableTrack.length == 0 ? 0 : "";
      }
      this.loading = false;
    },
    async GetTracking(tracklist) {
      let dataGet = [];
      try {
        let track = await axios.post(
          branchService_dotnet + "CodReport/get-odermasterid",
          {
            Tracking: tracklist,
          },
          { headers: header_token }
        );
        if (track.status == 200) {
          dataGet = track.data;
        } else if (track.status == 500) {
          dataGet = [];
          this.loading = false;
          Swal.fire({
            showConfirmButton: true,
            position: "center",
            icon: "warning",
            title: "เกิดข้อผิดพลาดกรุณาทำใหม่",
          });
        }
      } catch (e) {
        this.loading = false;
        console.log("error");
        Swal.fire({
          showConfirmButton: true,
          position: "center",
          icon: "warning",
          title: "เกิดข้อผิดพลาดกรุณาทำใหม่",
        });
      }
      return dataGet;
    },
    async updatecodconfirm() {
      this.loading = true;
      this.addcod.date = moment(this.addcod.date).format("YYYY-MM-DD HH:mm:ss");
      await axios
        .all([
          axios.post(
            branchService_dotnet + "CodReport/update-reportcod",
            {
              Id: this.addcod.id,
              Amount: this.addcod.amount,
              Remark: this.addcod.remark,
              Created: this.addcod.date,
              ExpressCompanyId: this.addcod.express,
              BankId: this.addcod.bank,
              branchId: localStorage.getItem("Branch_BranchID"),
              OrderList: this.dataTableTrack,
              TrackDelete: this.datatrackdelete,
              CreatedBy: localStorage.getItem("Packhai_StaffID"),
            },
            { headers: header_token }
          ),
        ])
        .then(
          axios.spread((update) => {
            if (update.status == 200) {
              this.DialogUpdateCodReport = false;
              this.load_data();
              Swal.fire({
                toast: true,
                timer: 1500,
                showConfirmButton: false,
                position: "top",
                icon: "success",
                title: update.data,
              });
            }
          })
        );
      this.loading = false;
    },
    async loaddetailtrack(detail) {
      this.loading = true;
      await axios
        .all([
          axios.post(
            branchService_dotnet + "CodReport/get-codreport-info",
            {
              CodReportId: detail.id,
            },
            { headers: header_token }
          ),
          axios.post(
            branchService_dotnet + "CodReport/get-bankcod",
            { branchId: localStorage.getItem("Branch_BranchID") },
            { headers: header_token }
          ),
        ])
        .then(
          axios.spread((Report, bank) => {
            if (Report.status == 200 && bank.status == 200) {
              this.addcod = {
                id: Report.data.id,
                amount: Report.data.amount,
                remark: Report.data.remark,
                date: this.formatDateTime(Report.data.created),
                track: null,
                express: Report.data.expressCompanyName,
                bank: Report.data.bankId,
                approvedByName: Report.data.approvedByName,
                approved: Report.data.approved,
                bankDate: Report.data.bankDate,
              };
              this.bankdate = this.formatDate(Report.data.bankDate);
              this.dataTableDetailcod = Report.data.orderList;
              this.DialogDialogDetailReport = true;
              for (var b in bank.data) {
                if (this.addcod.bank == bank.data[b]["bankId"]) {
                  this.addcod.bank = bank.data[b]["accountName"];
                }
              }
            }
          })
        );
      this.loading = false;
    },
    async DeleteCodReport(cod) {
      Swal.fire({
        icon: "warning",
        title: "ต้องการลบ ใช่ หรือ ไม่",
        confirmButtonText: "Yes!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          await axios
            .all([
              axios.post(
                branchService_dotnet + "CodReport/delete-codreport",
                {
                  CodReportId: cod.id,
                },
                { headers: header_token }
              ),
            ])
            .then(
              axios.spread((report) => {
                if (report.status == 200) {
                  this.load_data();
                  Swal.fire({
                    showConfirmButton: true,
                    position: "center",
                    icon: "success",
                    title: report.data,
                  });
                }
              })
            );
        }
      });
    },
    async exportexcel() {
      var d = new Date();
      var hour = d.getHours() % 24; // AM,PM format
      var minutes = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
      var time = hour + minutes;
      var date = this.bankdate.substr(0, 10);
      this.filename = "COD-Report_" + date + "_" + time + ".xlsx";
      return this.filename;
    },
    async exportexceladdtrack() {
      this.filename = "COD-Report_" + "track" + ".xlsx";
      return this.filename;
    },
    async Approve(cod) {
      Swal.fire({
        icon: "warning",
        title: "ต้องการคืนยอด ใช่ หรือ ไม่",
        confirmButtonText: "Yes!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading_btn = true;
          await axios
            .all([
              axios.post(
                branchService_dotnet + "CodReport/approved-codreport",
                {
                  CODReportID: cod.id,
                  StaffPackhaiID: localStorage.getItem("Branch_StaffID"),
                },
                { headers: header_token }
              ),
            ])
            .then(
              axios.spread((approved) => {
                if (approved.status == 200) {
                  if (approved.data.status == "success") {
                    for (var dt in this.dataTableCOD) {
                      if (this.dataTableCOD[dt]["id"] == cod.id) {
                        this.dataTableCOD[dt]["approvedByName"] =
                          approved.data.approvedBy;
                        this.dataTableCOD[dt]["approved"] = approved.data.approvedTime;
                      }
                    }
                    this.loading_btn = false;
                    Swal.fire({
                      toast: true,
                      timer: 1500,
                      showConfirmButton: false,
                      position: "top",
                      icon: "success",
                      title: "เรียบร้อย",
                    });
                  } else {
                    this.loading_btn = false;
                    Swal.fire({
                      showConfirmButton: true,
                      position: "center",
                      icon: "warning",
                      title: approved.data.message,
                    });
                  }
                }
              })
            );
        }
      });
    },
    price(price) {
      if (price == null) return parseFloat(0);
      var fprice = parseFloat(price).toFixed(2);
      return `${fprice}`;
    },
    async clearaddcod() {
      this.addcod = {
        id: 0,
        amount: null,
        remark: null,
        date: new Date(),
        track: null,
        express: null,
        bank: 0,
        approved: null,
        bankDate: null,
      };
      this.dataTableTrack = [];
      this.datatrackdelete = [];
      this.codamount = 0;
    },
    // exportToExcel(){
    //     // export json to Worksheet of Excel
    //     // only array possible
    //     var tbl = document.getElementById('sheetjs');

    //       var wb = XLSX.utils.book_new() // make Workbook of Excel
    //     // var wb = XLSX.utils.table_to_book(tbl);
    //     var wb = XLSX.utils.sheet_to_json(ws, {header:["A","E","I","O","U","6","9"]});
    //                                 [ { '6': 'J', '9': 'S', A: 'S', E: 'h', I: 'e', O: 'e', U: 't' },
    //                                 { '6': '6', '9': '7', A: '1', E: '2', I: '3', O: '4', U: '5' },
    //                                 { '6': '7', '9': '8', A: '2', E: '3', I: '4', O: '5', U: '6' } ]
    //     // add Worksheet to Workbook
    //     // Workbook contains one or more worksheets
    //     //   XLSX.utils.book_append_sheet(wb, animalWS, 'animals') // sheetAName is name of Worksheet
    //     //   XLSX.utils.book_append_sheet(wb, pokemonWS, 'pokemons')

    //     var d = new Date();
    //     var hour = d.getHours() % 24; // AM,PM format
    //     var minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
    //     var time = hour + minutes;
    //     var date = this.bankdate.substr(0, 10)
    //     var namefile = "COD-Report_"+date+"_"+time+".xlsx"
    //     // export Excel file
    //     XLSX.writeFile(wb, filename) // name of the file is 'book.xlsx'
    // },
    // async fetchData(){
    //     var data = {
    //                     "orderlist":this.dataTableDetailcod,
    //                     "amount":this.addcod.amount
    //                 }
    //     console.log(data);
    //     console.log(this.dataTableDetailcod);
    //     return this.dataTableDetailcod;

    // },
    DeleteTrack(trackingno) {
      Swal.fire({
        icon: "warning",
        title: "ต้องการลบ ใช่ หรือ ไม่",
        text: trackingno.trackingNo,
        confirmButtonText: "Yes!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          this.deleteindextrack = this.dataTableTrack.indexOf(trackingno);
          this.datatrackdelete.push({
            codAmount: trackingno.codAmount,
            orderMasterId: trackingno.orderMasterId,
            shopName: trackingno.shopName,
            trackingNo: trackingno.trackingNo,
          });
          this.dataTableTrack.splice(this.deleteindextrack, 1);
          this.codamount = this.codamount - trackingno.codAmount;
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style>
.border-left {
  border-left: 1px solid black !important;
}
.border-right {
  border-right: 1px solid black !important;
}
.border-top {
  border-top: 1px solid black !important;
}
.border-bottom {
  border-bottom: 1px solid black !important;
}
.border-x {
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
}
.border-y {
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
}
.border-a {
  border: 1px solid black !important;
}
.v-date-time-widget-container {
  background: white;
  padding: 15px;
}

.v-card {
  box-shadow: none;
}

.btn-am {
  float: left;
}

.btn-pm {
  float: right;
  margin-right: 4px;
}

.v-date-picker-table {
  height: auto;
}
</style>
